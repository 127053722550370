import "./style.scss";
import Skeleton from "react-loading-skeleton";

interface TableSkeletonProps {
  numberOfRows: number;
  numberOfColumns: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  numberOfRows,
  numberOfColumns,
}) => {
  return (
    <div className="table-skeleton-wrapper">
      <table className="table table-borderless table-skeleton">
        <tbody>
          {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: numberOfColumns }).map((_, colIndex) => (
                <td key={colIndex}>
                  <Skeleton height={35} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
