import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Table, Tooltip as BsTooltip } from "react-bootstrap";
import { useTable } from 'react-table';
import { TCasteTable } from './typescript';
import { useTranslation } from 'react-i18next';
import TableSkeleton from '../../../../widgets/skeleton/table';
import Errordiv from '../../../../widgets/alert/errordiv';
import { Link } from 'react-router-dom';
import editIcon from "../../../../assets/images/icons/edit-action.svg";
import deleteIcon from "../../../../assets/images/icons/delete-action.svg";

const CasteManagementTable: React.FC<TCasteTable> = ({
  apiStatus,
  casteData,
  editHandler,
  deleteHandler
}) => {
  const { t } = useTranslation();
 

  const tableColumn = [
    {
      Header: "Caste",
      accessor: "name",
      Cell : ({ row } : any)=>{
        // Access the hostelType from the row object
        const Caste = row.original.name;
  
         // Capitalize the first character of the hostelType
         const capitalizedCasteName = `${Caste
          .charAt(0)
          .toUpperCase()}${Caste.slice(1)}`;
        return capitalizedCasteName;
        }
      
    },
    {
      Header: "Short Name",
      accessor: "shortName",

      Cell : ({ row } : any)=>{
        // Access the hostelType from the row object
        const shortName = row.original.shortName;
  
         // Capitalize the first character of the hostelType
         const capitalizedShortName = `${shortName
          .charAt(0)
          .toUpperCase()}${shortName.slice(1)}`;
        return capitalizedShortName;
        }
    },
    {
      Header: t('common.actions'),
      Cell: ({ row }: any) => (
        <div className="action-icons-box">
          <OverlayTrigger
            placement="top"
            overlay={<BsTooltip>{t('common.uptUserDetail')}</BsTooltip>}
            trigger={["hover", "focus"]}
          >

            <Link
              className="action-icons"
              to=""
            // to={createEditLink(row.original.userId)}
            >
              <img src={editIcon} alt="Edit" onClick={() =>
                editHandler(row.original)}/>
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<BsTooltip>{t('common.deleteUser')}</BsTooltip>}
            trigger={["hover", "focus"]}
          >
            <Link className="action-icons" to="">
              <img src={deleteIcon} alt="Delete" onClick={() => deleteHandler(row.original.id)} />
            </Link>
          </OverlayTrigger>
        </div>
      ),

    },
  ];

  const columns: any[] = useMemo(() => tableColumn, []);
  const data: any[] = useMemo(() => casteData, [casteData]);
 
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <React.Fragment>
      <div className="table-responsive admin-table-wrapper mt-3">
        {/* <TimerAlertBox
          alertMsg={alertMsg.message}
          className="mt-3"
          variant={alertMsg.alertBoxColor}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        /> */}
        <Table borderless striped {...getTableProps}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()} key={index}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <td {...cell.getCellProps()} key={index}>
                      {/* {cell.render("Cell")}  */}

                      {cell.column.id !== "feeCategories" ? (
                        cell.render("Cell")
                      ) : (
                        cell.render("Cell")?.toString()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {apiStatus === true && casteData.length === 0 && (
          <TableSkeleton numberOfRows={5} numberOfColumns={4} />
        )}
        {apiStatus === false && casteData.length === 0 && (
          <Errordiv msg={t('common.noRecord')} cstate className="mt-3" />
        )}
      </div>

    </React.Fragment>
  )
}

export default CasteManagementTable